#section-announcement {
  background: #000000;
  color: #ffffff;
}

.announcement-bar {
  position: relative;
  text-align: center;
  font-size: 11px;
  z-index: 1;
}

.announcement-bar-wrapper {
  padding: 7px 15px;
}

.announcement-bar-content {
  padding: 6px 0px;
  letter-spacing: 0.2em;
  color: inherit;
  margin: 0;
}
.hide-at-mobile {
  display: block;
}
.show-at-mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .announcement-bar {

    font-size: 11px;
  }
  .announcement-bar-content {
    padding: 8px 0px;
  }
  .hide-at-mobile {
    display: none;
  }
  .show-at-mobile {
    display: block;
  }
}

.marquee {
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee p {
  display: inline-block;
  padding-left: 100%;
  will-change: transform;
  animation: marquee 25s linear infinite;
}

.marquee p:hover {
  animation-play-state: paused
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@media (prefers-reduced-motion: reduce) {
  .marquee {
    white-space: normal
  }
  .marquee span {
    animation: none;
    padding-left: 0;
  }
}