
.slide-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  overflow: hidden;
  line-height: 19px;
  margin: auto;
}

.slide-btn:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: white;
  position: absolute;
  transition: all 0.45s cubic-bezier(0.74, 0.03, 0.24, 0.96);
  right: 0;
}

.slide-btn:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

.slide-btn span {
  text-align: center;
  text-decoration: none !important;
  color: #fff;
  z-index: 2;
  letter-spacing: 0.2em;
  transition: all 0.45s cubic-bezier(0.74, 0.03, 0.24, 0.96);
}

.slide-btn:hover span {
  color: var(--color-cyan);
  text-decoration: none !important;
}

.slide-btn-reverse-color {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: white !important;
  background-color: white !important;
  overflow: hidden;
  line-height: 19px;
  margin: auto;
}

.slide-btn-reverse-color:after {
  content: " ";
  width: 0%;
  height: 100%;
  background-color: black !important;
  position: absolute;
  transition: all 0.45s cubic-bezier(0.74, 0.03, 0.24, 0.96);
  right: 0;
}

.slide-btn-reverse-color:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

.slide-btn-reverse-color span {
  text-align: center;
  text-decoration: none !important;
  color: var(--color-cyan);
  z-index: 2;
  transition: all 0.45s cubic-bezier(0.74, 0.03, 0.24, 0.96);
}

.slide-btn-reverse-color:hover {
  color: var(--color-cyan);
}
.slide-btn-reverse-color:hover span {

  color: white;
  text-decoration: none !important;
}
.fixed-font-size {
  font-size: 12.8px !important;
}
