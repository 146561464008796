
.sidebar-cart {
  max-height: 100vh;
  overflow-y: auto;
  font-family: "Nunito Sans", sans-serif;
  width: 400px;
  max-width: min(calc(100vw - 60px), 400px);
}
.drawer-cart-main-container {
  width: min(calc(100vw - 60px), 400px);
  max-width: min(calc(100vw - 60px), 400px);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.drawer-header {
  display: flex;
  align-items: center;
  position: relative;
  height: 50px;
  max-height: 60px;
  background: inherit;
  text-align: center;
  z-index: 1;
}

.drawer-header-title {
  font-size: 16px;
  letter-spacing: .2rem;
  text-transform: uppercase;
  font-family: "Nunito Sans", sans-serif;
}

.close-icon {
  display: inline-block;
  height: 1em;
  width: 1em;
  fill: currentColor;
  vertical-align: middle;
  stroke-width: 1px;
  background: none;
  pointer-events: none;
}

.drawer-close-icon {
  right: 18px;
  left: auto;
  position: absolute;
  margin-left: 0;
  top: calc(50% - 7px);
  line-height: 0;
}
.drawer-container {
  padding-left: 24px;
  padding-right: 18px;
  width: min(calc(100vw - 60px), 400px);
  max-width: calc(100vw - 60px);
  min-height: 60px;
  overflow: hidden;
}

.drawer-container p {
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 13px;
  word-wrap: normal;
  margin-bottom: 0rem;
}
.drawer-container-bordered {
  box-shadow: 0 -1px rgb(222, 222, 222) inset;
}

.drawer-cart {
  flex: 1 1 auto;
  overflow-y: auto;
}
.cart-footer-text {
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 20px;
  color: #6a6a6a;
}
.drawer-footer {
  padding: 14px 24px 24px;
  flex: none;
  box-shadow: 0 -1px var(--border-color);
  background-color: white;
  position: relative;
  z-index: 1;
}
.drawer-button {
  width: 100% !important;
  margin-bottom: 8px;
}
.drawer-button > span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.separator-dot {
  display: inline-block;
  margin: 0 18px;
  content: "";
  height: 3px;
  width: 3px;
  border-radius: 100%;
  background: currentColor;
}
.drawer-cart-item {
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.drawer-cart-item-img-box {
  min-width: 100px;
}
.drawer-cart-item-img {
  aspect-ratio: .75;
  width: 100px;
  min-width: 90px;
}
.drawer-cart-item-info {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.drawer-cart-item-info-price {
  letter-spacing: .2em;
  text-transform: uppercase;
}
.drawer-cart-item-info-title {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
  line-height: 1.2;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: min(240px, calc(100vw - 190px));
}
.drawer-cart-item-info-title > a{
  text-decoration: none !important;
  letter-spacing: .15em;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  color: black;
}
.drawer-cart-item-info-price-box {
  display: inline-flex;
  align-items: center;
  border: 1px solid #d2d2d2;
  white-space: nowrap;
  min-height: 40px;
  width: fit-content;
}
.drawer-cart-item-info-price-input {
  display: inline-block;
  width: 20px;
  padding: 0;
  font-size: 12px;
  color: #5e5e5e;
  text-align: center;
  letter-spacing: normal;
  background: transparent;
  border: none;
}
.drawer-cart-item-info-price-icon-wrapper {
  padding: 7px 14px 8px;
}
.drawer-cart-item-info-price-icon {
  width: 10px;
  height: 10px;
  stroke-width: 1.5px;
  vertical-align: -1px;
  cursor: pointer;
}
.drawer-cart-item-info-quantity {
  display: flex;
  margin-top: 28px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.drawer-cart-item-info-remove {
  color: #5e5e5e;
  margin: 8px 0;
  letter-spacing: .2em;
  text-transform: uppercase;
  font-size: 10px;
  cursor: pointer;
  margin-left: 8px;
}

.drawer-cart-item-info-remove-underline {
  position: relative;
  display: inline-block;
}

.drawer-cart-item-info-remove-underline::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background: currentColor;
  transform: scale(1, 1);
  transform-origin: left center;
  transition: transform 0.2s ease-in-out;
}

.drawer-cart-item-info-remove-underline:hover::before {
  transform: scale(0, 1);
}

.drawer-cart-item-info-empty-cart {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 13px;
  letter-spacing: .2em;
  text-transform: uppercase;
}

@media screen and (min-width: 641px) {
  .drawer-close-icon {
    right: 0px;
    left: auto;
  }
  .drawer-container{
    padding-left: 25px;
    padding-right: 25px;
  }
  .drawer-header {
    max-height: 80px;
    height: 72px;
    min-height: 60px;
  }
  .drawer-footer {
    padding: 20px 30px 30px;
  }
}

@media screen and (max-width: 479px) {
  .drawer-cart-item-img {
    aspect-ratio: .75;
    width: 70px;
  }
  .drawer-cart-item-img-box {
    min-width: 70px;
  }
  .drawer-cart-item-info {
    padding-left: 12px;
  }
  .drawer-container {
    padding-left: 14px;
    padding-right: 8px;
  }
  .drawer-header-title {
    font-size: 12px;
    width: calc(100vw - 120px);
  }
}
/* @media screen and (max-width: 400px) {
  .drawer-container {
    padding-left: 24px;
    padding-right: 18px;
    width: max(calc(100vw - 60px), 90%);
    max-width: 90%;
  }
} */